import {
  format,
  differenceInDays,
  parse,
  parseISO,
  isAfter,
  isSameDay
} from "date-fns"
import { pl } from "date-fns/locale"
import { PhotoUtils } from "./PhotoUtils"
import { useCategories, getDietOptions } from "./categories"
import { Product } from "../shared/wakacje-types"

export const provinces = {
  dolnoslaskie: "Dolnośląskie",
  "kujawsko-pomorskie": "Kujawsko-pomorskie",
  lubelskie: "Lubelskie",
  lubuskie: "Lubuskie",
  lodzkie: "Łódzkie",
  malopolskie: "Małopolskie",
  mazowieckie: "Mazowieckie",
  opolskie: "Opolskie",
  podkarpackie: "Podkarpackie",
  podlaskie: "Podlaskie",
  pomorskie: "Pomorskie",
  slaskie: "Śląskie",
  swietokrzyskie: "Świętokrzyskie",
  "warminsko-mazurskie": "Warmińsko-mazurskie",
  wielkopolskie: "Wielkopolskie",
  zachodniopomorskie: "Zachodniopomorskie"
}

export const months = [
  { name: "Styczeń", en_name: "January", value: 1 },
  { name: "Luty", en_name: "February", value: 2 },
  { name: "Marzec", en_name: "March", value: 3 },
  { name: "Kwiecień", en_name: "April", value: 4 },
  { name: "Maj", en_name: "May", value: 5 },
  { name: "Czerwiec", en_name: "June", value: 6 },
  { name: "Lipiec", en_name: "July", value: 7 },
  { name: "Sierpień", en_name: "August", value: 8 },
  { name: "Wrzesień", en_name: "September", value: 9 },
  { name: "Październik", en_name: "October", value: 10 },
  { name: "Listopad", en_name: "November", value: 11 },
  { name: "Grudzień", en_name: "December", value: 12 }
]

export const getMonthName = (mo) => {
  var f = months.find((m) => m.value === mo)
  return f?.name ?? mo
}

export const optionsTransportVehicle = [
  { value: "bus", label: "Autokar" },
  { value: "plane", label: "Samolot" },
  { value: "train", label: "Pociąg" }
]

export const PRODUCT_TYPE_CAMP = {
  name: "Kolonie i obozy",
  namePlurial: "Kolonie i obozy",
  value: "kolonie",
  color: "#FCD9CA"
}
export const PRODUCT_TYPE_DAYCAMP = {
  name: "Półkolonie",
  namePlurial: "Półkolonie",
  value: "polkolonie",
  color: "#DEEECE"
}
export const PRODUCT_TYPE_SCHOOL_TRIP = {
  name: "Wycieczka szkolna",
  namePlurial: "Wycieczki szkolne",
  value: "wycieczka-szkolna",
  color: "#DBF6FA"
}
export const PRODUCT_TYPE_GROUPS = {
  name: "Noclegi dla grup",
  namePlurial: "Noclegi dla grup",
  value: "noclegi-dla-grup",
  color: "#042675"
}
export const PRODUCT_TYPE_SCHOOL = {
  name: "Zielona szkoła",
  namePlurial: "Zielone szkoły",
  value: "zielona-szkola",
  color: "#DBFADC"
}
const types = [
  PRODUCT_TYPE_CAMP,
  PRODUCT_TYPE_DAYCAMP,
  PRODUCT_TYPE_SCHOOL_TRIP,
  PRODUCT_TYPE_SCHOOL,
  PRODUCT_TYPE_GROUPS
]

export enum ProductTypes {
  SCHOOL_TRIP = "wycieczka-szkolna",
  DAYCAMP = "polkolonie",
  CAMP = "kolonie",
  GROUPS = "noclegi-dla-grup"
}

export enum ProductStatus {
  DRAFT = "DRAFT", // new product - company is adding
  IN_REVIEW = "IN_REVIEW", // company wants to publish and sent to review to admin
  ACTIVE = "ACTIVE", // approved - now it is visible
  SUSPENDED = "SUSPENDED", // temporary suspended - now it is visible
  ARCHIVED = "ARCHIVED", // old product -was archived
  REJECTED = "REJECTED" //    review was rejected
}

export class ProductUtils {
  static getTransportTypeLabel(val: string, t) {
    if (val === "own") {
      return t("product.own", "Własny")
    }
    if (val === "provided") {
      return t("product.provided", "Zorganizowany")
    }
    return val
  }

  static getVehicleTypeLabel(val: string) {
    const r = optionsTransportVehicle.find((v) => v.value === val)
    return r?.label ?? val
  }

  static getTypeName(type: string) {
    const item = types.find((t: any) => t.value === type)
    return item ? item.name : type
  }
  static getTypeNamePlurial(type: string) {
    const item = types.find((t: any) => t.value === type)
    return item ? item.namePlurial : type
  }

  static getTripTypeLabel(type: string) {
    if (type === "jednodniowa") {
      return "Wycieczka jednodniowa"
    }
    if (type === "wielodniowa") {
      return "Wycieczka wielodniowa z noclegiem (zielona szkoła)"
    }
    return type
  }

  static typeLabel(type, plural: boolean = false) {
    if (type === "kolonie") {
      return "Kolonie i obozy"
    } else if (type === "polkolonie") {
      return "Półkolonie"
    } else if (type === "wycieczka-szkolna") {
      return plural ? "Wycieczki szkolne" : "Wycieczka szkolna"
    } else if (type === "zielona-szkola") {
      return plural ? "Zielone Szkoły" : "Zielona Szkoła"
    }
    return type
  }

  static productTypeColor(type) {
    switch (type) {
      case "wycieczka-szkolna":
        return "var(--fc-color-accent-3)"
      case "kolonie":
        return "var(--fc-color-1-red)"
      case "polkolonie":
        return "var(--fc-color-green)"
      case "zielona-szkola":
        return "black"
      case "dedykowana-wycieczka-szkolna":
        return "var(--fc-color-accent-3)"
      case "noclegi-dla-grup":
        return "var(--fc-color-blue)"
    }
  }

  static formatAvailabilityIntl(product, lang) {
    if (product.availability?.length === 12) {
      if (lang && lang === "en") {
        return "Whole year"
      }
      return "cały rok"
    }
    const res = product.availability.map((i) =>
      months.find((m) => m.value === i)
    )
    return res
      .filter((m) => !!m)
      .map((m) => {
        if (lang && lang === "en") {
          return m.en_name
        }
        return m.name
      })
      .join(", ")
  }

  static inquiryStartDateMonth(booking) {
    try {
      return format(
        parse(booking.start_date, "yyyy-MM-dd", new Date()),
        "LLLL",
        {
          locale: pl
        }
      )
    } catch (e) {}
    return undefined
  }
  static productLocationName(cat, t) {
    const category = useCategories(t).find((i) => i.id === cat)

    if (category) {
      return category.subcategory
    }
    if (cat === "ZA_GRANICA") {
      return "Za granicą"
    }
    if (cat === "POLSKA") {
      return "Polska"
    }
    return cat
  }

  static productCategoryName(cat, t) {
    const category = useCategories(t).find((i) => i.id === cat)

    return category ? category.subcategory : cat
  }

  static productTypeName(type, plurial?: boolean) {
    switch (type) {
      case "wycieczka-szkolna":
        return plurial ? "Wycieczki Szkolne" : "Wycieczka szkolna"
        break
      case "kolonie":
        return plurial ? "Kolonie i obozy" : "Kolonie i obozy"
        break
      case "polkolonie":
        return plurial ? "Półkolonie" : "Półkolonie"
        break
      case "zielona-szkola":
        return plurial ? "Zielone Szkoły" : "Zielona Szkoła"
        break
      default:
        return type
    }
  }

  static getProductTypeNameIntl = (
    type:
      | "wycieczka-szkolna"
      | "kolonie"
      | "polkolonie"
      | "dedykowana-wycieczka-szkolna"
      | "noclegi-dla-grup",
    lang: string,
    plurial?: boolean
  ) => {
    if (lang === "en") {
      switch (type) {
        case "wycieczka-szkolna":
          return plurial ? "School trips" : "School trip"
        case "dedykowana-wycieczka-szkolna":
          return plurial ? "School trips" : "School trip"
        case "kolonie":
          return plurial ? "Camps" : "Camp"
        case "polkolonie":
          return plurial ? "Day camps" : "Day camp"
        case "noclegi-dla-grup":
          return plurial ? "Group accomodations" : "Group accomodation"
      }
    }

    if (lang === "pl") {
      switch (type) {
        case "wycieczka-szkolna":
          return plurial ? "Wycieczki Szkolne" : "Wycieczka szkolna"
        case "dedykowana-wycieczka-szkolna":
          return plurial ? "Wycieczki Szkolne" : "Wycieczka szkolna"
        case "kolonie":
          return plurial ? "Kolonie i obozy" : "Kolonie i obozy"
        case "polkolonie":
          return plurial ? "Półkolonie" : "Półkolonie"
        case "noclegi-dla-grup":
          return plurial ? "Noclegi dla grup" : "Noclegi dla grup"
      }
    }
  }

  static transportTypeLabel(product, t) {
    if (product.transport_type === "own") {
      return t("product.own", "Własny")
    } else if (product.transport_type === "provided") {
      return t("product.provided", "Zorganizowany")
    }
    return product.transport_type
  }

  static transportTypesLabel(transportType, t) {
    if (transportType === "own") {
      return t("product.own", "Własny")
    } else if (transportType === "provided") {
      return t("product.provided", "Zorganizowany")
    }
    return transportType
  }

  static transportVehicleLabel(product, t) {
    if (product.transport_vehicle === "bus") {
      return t("transport.bus", "Autokar")
    } else if (product.transport_vehicle === "plane") {
      return t("transport.plane", "Samolot")
    } else if (product.transport_vehicle === "train") {
      return t("transport.train", "Pociąg")
    }
    return product.transport_vehicle
  }

  static formatPostalCode(value) {
    return value.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1-")
  }

  static productAddressString(product) {
    const addressParts = []

    if (product.location) {
      if (product.location && product.location?.street) {
        addressParts.push(product?.location?.street)
      }
      if (product.location?.city) {
        addressParts.push(product?.location?.city)
      }
      if (product.location?.province) {
        addressParts.push(product?.location?.province)
      }
    }
    if (product.country_name) {
      addressParts.push(product?.country_name)
    }
    return addressParts.length > 0 ? addressParts.join(", ") : ""
  }

  static productFullAddressString(item) {
    const addressParts = []
    if (!item) {
      return ""
    }
    if (item?.street) {
      addressParts.push(item.street)
    }

    if (item?.room_number) {
      addressParts.push(item.room_number)
    }
    if (item?.house_number) {
      addressParts.push(item.house_number)
    }
    if (item?.city) {
      addressParts.push(item.city)
    }

    if (item?.postcode) {
      addressParts.push(ProductUtils.formatPostalCode(item.postcode))
    }
    if (item?.province) {
      addressParts.push(item.province)
    }

    if (item?.country_name) {
      addressParts.push(item.country_name)
    }
    return addressParts.length > 0 ? addressParts.join(", ") : ""
  }

  static calculateProductLowestPrice(product) {
    return product.trips && product.trips.length > 0
      ? Math.min(...product.trips.map((trip) => trip.full_price))
      : 0
  }

  static calculateProductHighestPrice(product) {
    return product.trips
      ? Math.max(...product.trips.map((trip) => trip.full_price))
      : 0
  }
  static termsByMonths(product) {
    if (product.trips) {
      return Object.values(
        product.trips.reduce((a, trip) => {
          var month = format(
            parse(trip.start_date, "yyyy-MM-dd", new Date()),
            "LLLL",
            {
              locale: pl
            }
          )
          a[month] = month
          return a
        }, {})
      ).join(", ")
    }
    return undefined
  }

  static differenceInDaysYYYMMDD(start_date, end_date) {
    return differenceInDays(
      end_date.length === 10
        ? parse(end_date, "yyyy-MM-dd", new Date())
        : parseISO(end_date),
      start_date.length === 10
        ? parse(start_date, "yyyy-MM-dd", new Date())
        : parseISO(start_date)
    )
  }
  static tripDurationInDays(trip) {
    return trip.end_date && trip.start_date
      ? ProductUtils.differenceInDaysYYYMMDD(trip.start_date, trip.end_date) + 1 // we always add 1 day
      : undefined
  }
  // return array of possible durations (in days)
  static calculateAvailableDurations(product) {
    if (product && product.trips) {
      return (
        Object.values(
          product.trips?.reduce((a, trip) => {
            try {
              if (trip.start_date && trip.end_date) {
                var durationInDays =
                  differenceInDays(
                    trip.end_date.length === 10
                      ? parse(trip.end_date, "yyyy-MM-dd", new Date())
                      : parseISO(trip.end_date),
                    trip.start_date.length === 10
                      ? parse(trip.start_date, "yyyy-MM-dd", new Date())
                      : parseISO(trip.start_date)
                  ) + 1
                a[durationInDays] = durationInDays
              }
            } catch (e) {}
            return a
          }, {})
        ) ?? []
      )
    }
    return []
  }

  static formatDateRange(startDate, endDate) {
    return startDate && endDate
      ? format(parse(startDate, "yyyy-MM-dd", new Date()), "dd.MM") +
          " - " +
          format(parse(endDate, "yyyy-MM-dd", new Date()), "dd.MM.yyyy")
      : undefined
  }

  static formatDate = (date: string) => {
    if (Array.isArray(date)) {
      date = date[0]
    }
    try {
      return format(
        date.length == 12
          ? parse(date, "yyyy-MM-dd", new Date())
          : parseISO(date),
        "dd.MM.yyyy"
      )
    } catch (err) {
      return ""
    }
  }

  static formatDateShort = (date: string) => {
    try {
      return format(parse(date, "yyyy-MM-dd", new Date()), "dd.MM.yy")
    } catch (err) {
      return ""
    }
  }

  static formatDateFullMonth = (date: string) => {
    try {
      return format(parse(date, "yyyy-MM-dd", new Date()), "dd LLLL yyyy", {
        locale: pl
      })
    } catch (err) {
      return ""
    }
  }

  static formatDateTimeISO = (date: string) => {
    try {
      return format(parseISO(date), "HH:mm, dd.MM.yyyy")
    } catch (err) {
      return ""
    }
  }

  static formatIsoToDDMMYYY = (date: string) => {
    try {
      return format(parseISO(date), "dd.MM.yyyy")
    } catch (err) {
      return ""
    }
  }

  static formatDateTime = (date: string) => {
    try {
      return format(parse(date, "yyyy-MM-dd", new Date()), "hh:mi, dd.MM.yyyy")
    } catch (err) {
      return ""
    }
  }

  static formatDateWithDay = (date: string) => {
    try {
      return format(parse(date, "yyyy-MM-dd", new Date()), "iiii, dd.MM.yyyy", {
        locale: pl
      })
    } catch (err) {
      return ""
    }
  }

  static parseIsoDate(date: string) {
    return parseISO(date)
  }
  static parseDateYYYYMMDD(date: string) {
    return date
      ? date.length === 10
        ? parse(date, "yyyy-MM-dd", new Date())
        : parseISO(date)
      : undefined
  }
  static formatDateYYYYMMDD(date) {
    return format(date, "yyyy-MM-dd")
  }

  // This is actually dd.MM.yy and this is how we want it
  static formatDateYYMMDD = (date: string) => {
    try {
      return format(new Date(date), "dd.MM.yy")
    } catch (err) {
      return ""
    }
  }

  static formatDateLong = (date: string) => {
    try {
      return format(new Date(date), "dd.MM.yyyy")
    } catch (err) {
      return ""
    }
  }
  static generateProductLink = (product) => {
    return `/${product?.company?.fc_website_prefix}/${
      product.slug ?? product.id
    }`
  }

  static formatPrice(value, currencySymbol) {
    return typeof value === "number"
      ? ProductUtils.formatPriceWithSpace(value) + ` ${currencySymbol}`
      : undefined
  }
  static formatPriceInCents(value, currency = true, currencySymbol) {
    return value !== undefined && value !== null
      ? ProductUtils.formatPriceWithSpace(value / 100) +
          (currency ? ` ${currencySymbol}` : "")
      : undefined
  }
  static calculateAgeRange = (product) => {
    const ages = product.trips
      ? product.trips.reduce((a, i) => {
          if (i.min_age) {
            a.push(i.min_age)
          }
          if (i.max_age) {
            a.push(i.max_age)
          }
          if (i.min_age) {
            a.push(i.min_age)
          }
          if (i.max_age) {
            a.push(i.max_age)
          }
          return a
        }, [])
      : []
    if (ages.length > 0) {
      return [Math.min(...ages), Math.max(...ages)]
    }
    return [undefined, undefined]
  }

  static calculateAge = (birthDate) => {
    const date = new Date(Date.parse(birthDate))
    const currentDate = new Date()
    const age = (currentDate.getTime() - date.getTime()) / 31536000000
    const ageInYears = Math.floor(age)
    return ageInYears
  }

  static formatPriceWithSpace(price) {
    return price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")
  }

  static getProductPhotoUrl(product, w, h) {
    var mediaUrl = product?.main_photo
      ? product.main_photo.url
      : product?.media && product.media.length > 0
      ? product.media[0].url
      : "/svg/default-product-photo.svg"

    //Product that has "oferta dla ORGANIZATORÓW" in name should be cropped to north
    if (product?.name.toLowerCase().includes("oferta dla organizatorów")) {
      mediaUrl = PhotoUtils.generateCloudinaryLink(mediaUrl, w, h, "north")
    } else {
      mediaUrl = PhotoUtils.generateCloudinaryLink(mediaUrl, w, h, "auto")
    }

    return mediaUrl
  }

  static getPlacePhotoUrl(place, w, h) {
    var mediaUrl =
      place?.media && place.media.length > 0
        ? place.media[0].url
        : "/svg/default-product-photo.svg"

    mediaUrl = PhotoUtils.generateCloudinaryLink(mediaUrl, w, h)

    return mediaUrl
  }

  // True if
  static isTripInFuture(trip: any) {
    return (
      trip?.start_date &&
      (isSameDay(ProductUtils.parseYYYYMMDD(trip.start_date), new Date()) ||
        isAfter(ProductUtils.parseYYYYMMDD(trip.start_date), new Date()))
    )
  }

  static getTripDatesRange(trip) {
    console.log("getTripDatesRange", trip)
    if (!trip.start_date || !trip.end_date) {
      return ""
    }
    return (
      format(parse(trip.start_date, "yyyy-MM-dd", new Date()), "dd.MM.yy") +
      " - " +
      format(parse(trip.end_date, "yyyy-MM-dd", new Date()), "dd.MM.yy")
    )
  }
  static parseYYYYMMDD(date) {
    if (!date) {
      return undefined
    }
    if (typeof date === "object") {
      return date
    }
    if (date.length == 10) {
      return parse(date, "yyyy-MM-dd", new Date())
    }
    if (date.length > 10) {
      return parseISO(date)
    }
    return date
  }

  static sortTrips(trips) {
    trips?.sort((a, b) =>
      a.start_date ? a.start_date.localeCompare(b.start_date) : 0
    )
    return trips
  }

  static bookingId(id) {
    return id.replace("book_", "")
  }

  static formatPhone(phone) {
    if (phone.length === 12) {
      // +48 123-456-789
      return `${phone.substring(0, 3)} ${phone.substring(
        3,
        6
      )}-${phone.substring(6, 9)}-${phone.substring(9, 12)}`
    } else {
      //123-456-789
      return phone
        .replace(/([^\d])/g, "")
        .replace(/(\d)(\d)(\d)(?!$)/g, "$1$2$3-")
    }
  }

  static getProductCategoryName(category, t) {
    const allCategories = useCategories(t)

    for (const singleCategory of allCategories) {
      console.log("getProductCategoryName", category)
      console.log("getProductCategoryName", singleCategory)
      if (category === singleCategory.id) {
        return singleCategory.subcategory
      }
    }
    return category
  }

  static getProductCategoryIcon(category) {
    let categoryArr = category.split(".")
    if (categoryArr[1] === "jeziora") return "location-lake"
    if (categoryArr[1] === "morze") return "location-sea"
    if (categoryArr[1] === "gory") return "location-moutain"
    if (categoryArr[1] === "wies") return "location-village"
    if (categoryArr[1] === "las") return "location-forest"
    if (categoryArr[1] === "rzeka") return "location-river"
    if (categoryArr[1] === "miasto") return "location-city"
    if (categoryArr[1] === "polska") return "location-poland"
    if (categoryArr[1] === "zagranicą") return "location-abroad"
  }

  static getDietName(value, t) {
    const dietOptions = getDietOptions(t)
    const dietOptionsElement = dietOptions.find((v) => v.value === value)

    return dietOptionsElement?.name ?? value
  }

  static getCurrencyCode(currency) {
    if (currency === "USD") {
      return "$"
    }
    if (currency === "GBP") {
      return "£"
    }
    return "zł"
  }

  static formatDuration = (day: string) => {
    if (day === "1") return "1 dzień"
    if (day === "2-5") return "2-5 dni"
    if (day === "6-9") return "6-9 dni"
    if (day === "10-14") return "10-14 dni"
    if (day === "14+") return "powyżej 14 dni"
    return null
  }

  static formatHour = (hour: number) => {
    if (hour === null) {
      return " "
    }
    if (hour >= 0 && hour <= 2359) {
      const formattedHour = hour.toString().padStart(4, "0")

      const hours = formattedHour.substring(0, 2)
      const minutes = formattedHour.substring(2)

      return `${hours}:${minutes}`
    } else {
      return " "
    }
  }
  static buildPickupRouteOptions = (product: Product, t: (s: string, opt?: string) => string) => {
    // departure places
    var pickupRoute = product?.pickup_route
      ? product?.pickup_route.map((route) => {
          return {
            id: route.address,
            type: "provided",
            name: route.address ? route.address.trim() : "",
            price_cents: route.price_cents
              ? route.price_cents
              : route.price
              ? route.price * 100
              : 0
          }
        })
      : []

    pickupRoute.sort((a, b) => {
      return a.name?.localeCompare(b.name)
    })
    pickupRoute.push({
      id: "own",
      type: "own",
      name: t('product.own_transport', 'Dojazd własny'),
      price_cents: 0
    })
    return pickupRoute
  }

  static buildPickupRouteForIndividualOffer = (product: Product, t: (s: string, opt?: string) => string) => {
    var pickupRoute =
      product?.transport_type === "provided"
        ? [
            {
              id: product?.pickup_route_address,
              type: "provided",
              name: product?.pickup_route_address
              //TODO: nie wiem co z tym zrobić, zostawiam, ale wyciszam tutaj
              // price_cents: product?.pickup_route_price * 100
            }
          ]
        : [
            {
              id: "own",
              type: "own",
              name: t('product.own_transport', 'Dojazd własny'),
              price_cents: 0
            }
          ]
    return pickupRoute
  }

  static buildAllPickupOptions = (products: Product[]) => {
    const placesWithPickup = products.filter((el) => el.pickup_route)
    const uniqueLocations = Array.from(
      new Set(
        placesWithPickup.flatMap((place) =>
          place.pickup_route.map((route) => {
            let regex = /\t/g
            const el = route.address.trim().replace(regex, "")
            return el
          })
        )
      )
    )

    uniqueLocations.sort((a, b) => {
      return a.localeCompare(b)
    })

    return uniqueLocations
  }

  static buildArriveAtOptions = (products: Product[]) => {
    const locationsInPoland = products.filter(
      (el) => el.country_name === "Polska"
    )
    const locationsAbroad = products.filter(
      (el) => el.country_name !== "Polska"
    )
    const uniqueCountriesLocationsAbroad = Array.from(
      new Set(
        locationsAbroad.map((place) => {
          let regex = /\t/g
          const el = place.country_name.trim().replace(regex, "")
          return el
        })
      )
    )

    const locationsPoland = locationsInPoland.map((place) => {
      let regex = /\t/g
      const province = place.location?.province.trim().replace(regex, "")
      const city = place.location?.city.trim().replace(regex, "")
      return { province, city }
    })

    locationsPoland.sort((a, b) => {
      return a.province.localeCompare(b.province)
    })

    uniqueCountriesLocationsAbroad.sort((a, b) => {
      return a.localeCompare(b)
    })

    // console.log('!!!!!!!!!!!!!arrive at locations abroad after',uniqueCountriesLocationsAbroad.length)
    // console.log('!!!!!!!!!!!!!arrive at locations poland after',locationsPoland.length)

    return { uniqueCountriesLocationsAbroad, locationsPoland }
  }

  static calculateVisibleTrips = (product: Product, duration?) => {
    const visibleTrips =
      product.trips?.filter((t: any) => {
        return ProductUtils.isTripInFuture(t) && t.start_date && t.end_date
          ? duration === undefined ||
              duration === "" ||
              ProductUtils.differenceInDaysYYYMMDD(t.start_date, t.end_date) +
                1 ==
                duration
          : false
      }) ?? []

    visibleTrips.sort((a, b) =>
      a.start_date ? a.start_date.localeCompare(b.start_date) : 0
    )
    return visibleTrips
  }
}
